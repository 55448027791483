import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { isNeedToAbortJoinFlowAfterLogin } from './isNeedToAbortJoinFlowAfterLogin';
import { createParticipant } from './createParticipant';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';
import { payForChallenge } from './payForChallenge';
import {
  getTimeZone,
  getUserType,
  navigateToThankYouPage,
  promptLogin,
  toServerStartDate,
} from './userContextHelpers';
import userTypeHandlers from './userTypeHandlers';
import { IUserProviderProps } from '../UserProvider';
import { loadingPropsMap } from '../../Loading/loadingPropsMap';
import { initChallengePage } from '../../../components/ChallengesPage/controller';
import { biChangePage } from '../../../services/biHelpers';
import { ScreenNames } from '../../main/biInterfaces';

export async function joinToChallenge(
  flowAPI: ControllerFlowAPI,
  userProvider: IUserProviderProps,
  selectedPaymentOption: SelectedPaymentOption,
  startDate?: string,
  settings?: { showOneAppInfo?: boolean },
) {
  const startDateFormatted = toServerStartDate(startDate);
  const timeZone = getTimeZone(flowAPI);
  const loadingProviderProps = loadingPropsMap(flowAPI);

  loadingProviderProps.showLoader();

  if (!flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn) {
    try {
      await promptLogin(flowAPI);
      await userProvider.updateParticipant();
    } catch (e) {
      loadingProviderProps.hideLoader();
      return;
    }

    if (await isNeedToAbortJoinFlowAfterLogin(flowAPI)) {
      loadingProviderProps.hideLoader();
      return;
    }
  }

  const currentParticipantUserType = getUserType(
    { loggedIn: true },
    userProvider.participant,
  );

  if (
    userTypeHandlers.isJoinedAlreadyWithoutSuspended(currentParticipantUserType)
  ) {
    loadingProviderProps.hideLoader();
    return;
  }

  const participant = await createParticipant({
    timeZone,
    userProvider,
    startDateFormatted,
    flowAPI,
  });

  if (participant) {
    switch (participant.transitions[0].state) {
      case ParticipantState.PAYMENT_REQUESTED:
      case ParticipantState.PAYMENT_STARTED:
      case ParticipantState.SUSPENDED:
        const { userJoined } = await payForChallenge(
          flowAPI,
          userProvider,
          selectedPaymentOption,
          participant.id,
          startDateFormatted,
          timeZone,
          settings,
        );
        if (userJoined) {
          return navigateToThankYouPage(flowAPI);
        }
        return;
      case ParticipantState.JOIN_REQUESTED:
      case ParticipantState.JOINED:
      case ParticipantState.RUNNING:
        await initChallengePage(flowAPI); //  the way to update the page w/o reload
        await biChangePage(flowAPI.bi, ScreenNames.ChallengePageForParticipant);
        loadingProviderProps.hideLoader();

        flowAPI.controllerConfig.setProps({
          showJoinedToast: true,
        });

        return null;
      default:
        flowAPI?.errorMonitor?.captureMessage(
          `[challenge] Unexpected participant state "${participant?.transitions[0].state}" during join.`,
        );
        loadingProviderProps.hideLoader();
    }
  }
  loadingProviderProps.hideLoader();
  console.error('[CHALLENGE] Unable to join user');
}
