export enum ButtonNames {
  OneAppInfo = 'one_app_info',
  ViewChallenge = 'viewChallenge',
  BackToChallenge = 'backToChallenge',
  LoginAndJoinToTheChallenge = 'loginAndJoinToTheChallenge',
  JoinToTheChallenge = 'joinToTheChallenge',
  GoToOneApp = 'goToOneApp',
  OpenDotsMenu = 'OpenDotsMenu',
  StepComplete = 'StepComplete',
  LeaveTheChallenge = 'LeaveTheChallenge',
  LeaveTheChallengeAtModal = 'LeaveTheChallengeModal',
  CancelLeaveTheChallengeModal = 'CancelLeaveTheChallengeModal',
  StartDateSelection = 'StartDateSelection',
  ShareCompletedStep = 'ShareCompletedStep',
  ShareFacebook = 'ShareFacebook',
  ShareTwitter = 'ShareTwitter',
  ShareLinkedin = 'ShareLinkedin',
  CopyLink = 'CopyLink',
  CloseModalButton = 'CloseModalButton',
  OverviewTileSelection = 'OverviewSelection',
  StepTileSelection = 'StepTileSelection',
  NextStep = 'NextStep',
  OverviewStartButton = 'OverviewStartButton',
  NextSection = 'NextSection',
  toggleFullscreenMode = 'toggleFullscreenMode',
  GotItFromCantJoinModal = 'GotItFromCantJoinmodal',
  OtherPaymentMethodsFromCantJoinModal = 'OtherPaymentMethodsFromCantJoinModal',
  GoToInstructorProfile = 'GoToInstructorProfile',
  RenewParticipation = 'RenewParticipation',

  SeeFeedback = 'SeeFeedback',
  QstnSkipModal = 'QstnSkipModal',
  QstnCancelModal = 'QstnCancelModal',
  QstnSendModal = 'QstnSendModal',
  QstnAddTextResponseModal = 'QstnAddTextResponseModal',
  QstnClearTextResponseModal = 'QstnClearTextResponseModal',
  QstnAddQuantityResponseModal = 'QstnAddQuantityResponseModal',
  QstnClearQuantityResponseModal = 'QstnClearQuantityResponseModal',
  QstnAddMediaResponseModal = 'QstnAddMediaResponseModal',
  QstnClearMediaResponseModal = 'QstnClearMediaResponseModal',
  QstnAddChoiceResponseModal = 'QstnAddChoiceResponseModal',
  QstnClearChoiceResponseModal = 'QstnClearChoiceResponseModal',
  QuizCancelModal = 'QuizCancelModal',
  QuizSkipModal = 'QuizSkipModal',
  QuizCheckModal = 'QuizCheckModal',
  QuizNextModal = 'QuizNextModal',
  QuizSendModal = 'QuizSendModal',
  QuizAddChoiceResponseModal = 'QuizAddChoiceResponseModal',
  QuizClearChoiceResponseModal = 'QuizClearChoiceResponseModal',

  CategoryTabChange = 'CategoryTabChange',
  QuizSubmit = 'QuizSubmit',
  QuizResubmit = 'QuizResubmit',

  GetCertificate = 'GetCertificate',

  RemoveCoupon = 'RemoveCoupon',
  ApplyCoupon = 'ApplyCoupon',
}

export enum ActionTypes {
  CHALLENGE_JOIN = 'challenge_join',
  PAYMENT = 'payment_request',
  CREATE_PAYMENT_ORDER_ID = 'create_payment_order_id',
  JOIN_WITH_PAYMENT = 'join_with_payment',
  JOIN_REQUEST_CREATE = 'join_request_create',
  JOIN_REQUEST_CANCEL = 'join_request_cancel',
  STEP_COMMENT_SAVE = 'step_comment_save',
  FEEDBACK_SEND = 'feedback_send',
  FEEDBACK_SAVE = 'feedback_save',
  NOTIFICATION_SETTINGS_SET = 'notification_settings_set',
  STEP_COMPLETE = 'step_complete',
  STEP_FEEDBACK_UPDATE = 'STEP_FEEDBACK_UPDATE',
  CHALLENGE_COMPLETE = 'challenge_complete',
  CHALLENGE_LEFT = 'challenge_left',
  LEAVE_THE_CHALLENGE = 'leave_the_challenge',
  START_DATE_SELECTED = 'start_date_selected',
  STEP_UNDO = 'step_undo',
}

export enum ScreenNames {
  ChallengesListPage = 'ChallengesListPage',
  PaymentPage = 'PaymentPage',
  ChallengePage = 'ChallengeDetails',
  ChallengePageForParticipant = 'ChallengePageForParticipant',
  ChallengeListWidget = 'ChallengesList',
  MAChallengeList = 'MAChallengesList',
  ThankYouPage = 'ThankYouPage',
}

export enum TabNames {
  ChallengeParticipantSchedule = 'ChallengeParticipantSchedule',
  ChallengeParticipantOverview = 'ChallengeParticipantOverview',
}

export interface IBiSettings {
  screenName: ScreenNames;
  preventAutomaticallySendChallengeId?: boolean;
}
